/* eslint-disable */
var baseApiUrl = ''
var appBaseUrl = ''
if (process.env.NODE_ENV === 'production') {
  // baseApiUrl = 'https://discussion-tool-demo-api.geodemosolutions.com/public/'
  // appBaseUrl = 'https://discussion-tool-demo.geodemosolutions.com/#/'
  baseApiUrl = 'https://chbp-ccra-tool-api.geodemosolutions.com/public/'
  appBaseUrl = 'https://chbp-ccra-tool.geodemosolutions.com/#/'
 }
else {
  baseApiUrl = 'http://localhost/coffs-cra-api/public/'
  appBaseUrl = 'http://localhost:7800/#/'
}

export {
  baseApiUrl,
  appBaseUrl
}
