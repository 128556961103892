/*eslint-disable*/
import axios from 'axios';
import {baseApiUrl} from "../../../config.js";

const state = {
  currentProjectId: null,
  currentProject: null,
  projectList: [],
  isStageNavActive: false,
  currentActivePrimaryAsset: [],
  currentActiveClimateHazards: [],
  currentProjectAssetCriticalityList: [],
  currentProjectClimateCriticalityList: [],
  projectMapScenes:[
    {
        code: 'CHB',
        mapId: '80b6ebb4b31c4ab2812e2d34e68cc50c',
        sceneId: '',
        climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=9413f17f-84eb-4f42-8c93-e52d0d44be34&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false&navContentPaneEnabled=false',
        vulDashboardURL: ''
        // climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=31cf92f9-4f4e-42d9-a19e-576fd974dbc0&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&$filter=Rainfall_Daily/Bureau_x0020_of_x0020_Meteorology_x0020_station_x0020_number eq 66080 and Temp_Maximum_Daily/Bureau_x0020_of_x0020_Meteorology_x0020_station_x0020_number eq 66124&filterPaneEnabled=false&navContentPaneEnabled=false'
    }
    // {
    //     code: 'GWRR',
    //     mapId: '0a38d676288a4e5688685fe39f8cf5e4',
    //     sceneId: '367ac9d2ff704c0a8d580003f7e9ba8f',
    //     climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=31cf92f9-4f4e-42d9-a19e-576fd974dbc0&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&$filter=Rainfall_Daily/Bureau_x0020_of_x0020_Meteorology_x0020_station_x0020_number eq 67119 and Temp_Maximum_Daily/Bureau_x0020_of_x0020_Meteorology_x0020_station_x0020_number eq 67019&filterPaneEnabled=false&navContentPaneEnabled=false'
    // },
  ],
  projectAssessmentStakeholdersList: [],
  assessmentStagesList: []
}

function initialState() {
    return {
        currentProjectId: null,
        currentProject: null,
        projectList: [],
        isStageNavActive: false,
        currentActivePrimaryAsset: [],
        currentActiveClimateHazards: [],
        currentProjectAssetCriticalityList: [],
        currentProjectClimateCriticalityList: [],
        projectMapScenes:[
            {
              code: 'CHB',
              mapId: '80b6ebb4b31c4ab2812e2d34e68cc50c',
              sceneId: '',
              climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=9413f17f-84eb-4f42-8c93-e52d0d44be34&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false&navContentPaneEnabled=false',
              vulDashboardURL: ''
            },
        ],
        projectAssessmentStakeholdersList: [],
        assessmentStagesList: []
    }
}
const mutations = {
  clearProjectEntries(state) {
    state.projectList = []
  },
  setProjectEntries(state, payload) {
    state.projectList.push(payload)
  },
  setCurrentProject(state, payload) {
    state.currentProjectId = payload
    var currentProject = state.projectList.filter(obj=>{return obj.id == payload})
    state.currentProject = currentProject[0]
  },
  clearCurrentProject(state) {
    state.currentProjectId = null
    state.currentProject = null
  },
  resetStageNav(state) {
    state.isStageNavActive = false
  },
  setStageNav(state, payload) {
    state.isStageNavActive = payload
  },
  clearCurrentProjectPrimaryAsset(state, payload){
    state.currentActivePrimaryAsset = []
  },
  setCurrentProjectPrimaryAsset(state, payload){
    let primaryAssetType = {}
    primaryAssetType.label = payload.name
    primaryAssetType.id = payload.id
    primaryAssetType.code = payload.code
    primaryAssetType.designLife = payload.design_life
    primaryAssetType.isActive = payload.is_active==1?true:false
    state.currentActivePrimaryAsset.push(primaryAssetType)
  },
  clearCurrentClimateHazards(state, payload){
    state.currentActiveClimateHazards = []
  },
  setCurrentClimateHazards(state, payload){
    state.currentActiveClimateHazards.push(payload.code)
  },
  clearCurrentProjectAssetCriticalityList(state) {
    state.currentProjectAssetCriticalityList = []
  },
  setCurrentProjectAssetCriticalityList(state, payload) {
    payload.forEach(item=>{
        state.currentProjectAssetCriticalityList.push(item)
    })
  },
  clearCurrentProjectClimateCriticalityList(state) {
    state.currentProjectClimateCriticalityList = []
  },
  setCurrentProjectClimateCriticalityList(state, payload) {
    payload.forEach(item=>{
        state.currentProjectClimateCriticalityList.push(item)
    })
  },
  clearProjectAssessmentStakeholders(state) {
    state.projectAssessmentStakeholdersList = []
  },
  setProjectAssessmentStakeholders(state, payload) {
    state.projectAssessmentStakeholdersList.push(payload)
  },
  clearAssessmentStages(state) {
    state.assessmentStagesList = []
  },
  setAssessmentStages(state, payload) {
    state.assessmentStagesList.push(payload)
  },
}

const actions = {
  fetchProjects({commit, dispatch}) {
    return new Promise(function(resolve, reject){
        try {
            let url = baseApiUrl + "projects"
            axios.get(url).then(result=>{
                commit("clearProjectEntries")
                result.data.forEach(item=>{
                    commit("setProjectEntries", item)
                })
                resolve(true)
            })
        } catch (error) {
            reject(error)
        }
    })
  },
  fetchProjectActivePrimaryAssets({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "projectActivePrimaryAssets"
        let data = {
          projectId: state.currentProjectId
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
            commit('clearCurrentProjectPrimaryAsset')
            result.data.activePrimaryAssets.forEach(item=>{
                commit('setCurrentProjectPrimaryAsset', item)
              })
              
              resolve()
          })
      })
  },
  fetchProjectActiveClimateHazards({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "projectClimateHazards"
        let data = {
            params: {

                projectId: state.currentProjectId
            }
        }
        let config  = {
            responseType: 'json'
        }
        axios.get(url, data, config).then(result=>{
            commit('clearCurrentClimateHazards')
            result.data.projectClimateHazards.forEach(item=>{
                commit('setCurrentClimateHazards', item)
              })
              resolve()
          })
      })
  },
  fetchProjectAssetCriticalityList({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + 'projectAssetCriticalityChecklist'
        let data = {
            params: {
                projectId: payload
            }
        }
        let config = {
            responseType: 'json'
        }
        axios.get(url, data, config). then(result=>{
            commit('clearCurrentProjectAssetCriticalityList')
            commit('setCurrentProjectAssetCriticalityList', result.data.assetCriticalityList)
            resolve()  
        })
    })
  },
  fetchProjectClimateCriticalityList({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + 'projectClimateCriticalityChecklist'
        let data = {
            params: {
                projectId: payload
            }
        }
        let config = {
            responseType: 'json'
        }
        axios.get(url, data, config). then(result=>{
            commit('clearCurrentProjectClimateCriticalityList')
            commit('setCurrentProjectClimateCriticalityList', result.data.climateCriticalityList)
            resolve()  
        })
    })
  },
  updateActiveClimateHazards({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "updateActiveClimateHazards"
        let data = {
            activeCodes: payload.activeCodes,
            projectId: payload.projectId
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
            dispatch('fetchProjectActiveClimateHazards').then(()=>{
                resolve()
            })
        })
    })
  },
  updateActivePrimaryAssetTypes({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "updateActivePrimaryAssetTypes"
        let data = {
            projectId: state.currentProjectId,
            primaryAssetData: payload
        }
        let config  = {
            responseType: 'json'
        }
        axios.post(url, data, config).then(result=>{
            dispatch('fetchProjectActivePrimaryAssets').then(()=>{
                    resolve();
            })
        })
    })
  },
  fetchAssessmentStages({commit, dispatch}) {
    return new Promise(function(resolve, reject){
        try {
            let url = baseApiUrl + "assessmentStages"
            axios.get(url).then(result=>{
                commit("clearAssessmentStages")
                result.data.forEach(item=>{
                    commit("setAssessmentStages", item)
                })
                resolve(true)
            })
        } catch (error) {
            reject(error)
        }
    })
  },
  fetchProjectAssessmentStakeholders({commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        try {
            let url = baseApiUrl + "projectAssessmentStakeholders"
            let data = {
                params: {
                    projectId: payload
                }
            }
            let config = {
                responseType: 'json'
            }
            axios.get(url, data, config).then(result=>{
                commit("clearProjectAssessmentStakeholders")
                result.data.forEach(item=>{
                    commit("setProjectAssessmentStakeholders", item)
                })
                resolve(true)
            })
        } catch (error) {
            reject(error)
        }
    })
  },
  updateProjectAssessmentStakeholders({state, commit, dispatch}, payload) {
    return new Promise(function(resolve, reject){
        let url = baseApiUrl + "projectAssessmentStakeholders"
        
        let config  = {
            responseType: 'json'
        }

        payload.forEach((item,index) => {
          let data = {
            projectId: item.projectId,
            code: item.code,
            attendees: item.attendees
          }
          axios.put(url, data, config).then(result=>{
            resolve();
          })
        })
        
        
    })
  },
}

const getters = {}



export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
