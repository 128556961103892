<template>
  <div class="">
        <div class="row">
            <b-card
                title=""
                class="col-12 m-2 p-0"
                no-body>
                <template #header>
                    <div class="">
                        <h1 class="mb-0">Program Dashboard</h1>
                    </div>
                    
                    
                </template>
            </b-card>
        </div>
        <div class="row chartRow mb-2 pb-2">
            <div class="col-4">
                <b-card>
                    <div class="row">
                        <div class="col text-right">
                            <b-button 
                            variant="outline-secondary"
                            size="sm"
                            v-b-popover.hover.top="'Total number of Coffs Harbour Bypass project that have undertaken the Climate Risk Assessment process.'" >
                                <i class="fas fa-question-circle"></i>
                            </b-button>
                        </div>
                    </div>
                    <div id="proportionProjectsCRA" v-if="proportionProjectsCRALoadFlag">
                        <PieChart
                            :chartData="proportionProjectsCRAData"
                            :options="proportionProjectsCRADataOptions"
                            :height="200"/>
                    </div>
                    <div v-else>
                        Data Loading...
                    </div>
                </b-card>
            </div>
            <div class="col-4">
                <div class="">
                    <b-card
                        title=""
                        >
                        <div class="mb-4">
                          <div class="row">
                              <div class="col text-right">
                                <b-button 
                                  variant="outline-secondary"
                                  size="sm"
                                  v-b-popover.hover="'Total number of adaptation actions implemented'" >
                                  <i class="fas fa-question-circle"></i>
                                </b-button>
                              </div>
                          </div>
                          <div class="row m-0">
                            <div class="col m-0">
                              <h5 class="font-weight-bold card-title text-secondary mb-0"><i class="fa fa-chart-line"></i> Adaptation Actions</h5>
                              <br>
                                <div class="row m-0">
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary justify-content-center">Timing</span>
                                    </div>
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary justify-content-center">Count</span>
                                    </div>
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary justify-content-center">Implemented</span>
                                    </div>
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary ml-4 pl-4 justify-content-center">%</span>
                                    </div>
                                </div>
                                <br>
                              <div v-for="(item, index) in totalNumberOfAdaptations" :key="index">
                                <div class="row mb-1">
                                  <div class="col d-flex justify-content-center">
                                    <span class=" text-secondary">{{ item.label }}</span>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                    <span class="h5 font-weight-bold mb-0">{{ item.adaptationActionTotal }}</span>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                    <span class="h5 font-weight-bold mb-0">{{ item.adaptationActionImplemented }}</span>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                    <span class="h5 font-weight-bold mb-0 " :class="(item.adaptationActionImplementedPercentage<30?'text-danger':(item.adaptationActionImplementedPercentage<70?'text-warning':'text-success'))">{{ item.adaptationActionImplementedPercentage.toFixed(2)}}</span>
                                  </div>
                                </div>
                                
                              </div>
                              

                            </div>
                            <div class="col-auto">
                              
                            </div>
                          </div>
                          
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="col-4">
                <b-card>
                    <div class="row">
                        <div class="col text-right">
                            <b-button 
                            variant="outline-secondary"
                            size="sm"
                            v-b-popover.hover.top="'Program-level climate risk profile across Coffs Harbour Bypass project'" >
                                <i class="fas fa-question-circle"></i>
                            </b-button>
                        </div>
                    </div>
                    <div id="totalProgramRiskProfile" v-if="totalProgramRiskProfileFlag">
                        <PieChart
                            :chartData="totalProgramRiskProfileData"
                            :options="totalProgramRiskProfileOptions"
                            :height="200"/>
                    </div>
                    <div v-else>
                        Data Loading...
                    </div>
                </b-card>
            </div>
        </div>
        <br>
        <div class="row chartRow">
            <div class="col-4">
                <b-card>
                    <div class="row">
                        <div class="col">
                            <b-dropdown :text="assetYearDropdownText" variant="outline-secondary" size="sm" class="m-md-2">
                            <b-dropdown-item v-for="yearCategory in yearCategories" :key="yearCategory.code" @click="fetchRiskRatingsAssetElement(yearCategory.code, yearCategory.label)">{{ yearCategory.label }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="col text-right">
                            <b-button 
                            variant="outline-secondary"
                            size="sm"
                            v-b-popover.hover.top="'Program level summary of risk ratings associated with different asset component.'" >
                                <i class="fas fa-question-circle"></i>
                            </b-button>
                        </div>
                    </div>
                    <div id="riskRatingsAssetElementChart">
                        <BarChart
                            v-if="riskRatingsAssetElementLoadFlag"
                            :chart-data="riskRatingsAssetElementData"
                            :options="riskRatingsAssetElementDataOptions"
                            :height="200"/>
                            <div v-else>
                                Data Loading...
                            </div>
                    </div>
                    
                </b-card>
            </div>
            <div class="col-4">
                <b-card>
                    <div class="row">
                        <div class="col">
                            <b-dropdown :text="climateHazardYearDropdownText" variant="outline-secondary" size="sm" class="m-md-2">
                            <b-dropdown-item v-for="yearCategory in yearCategories" :key="yearCategory.code" @click="fetchRiskRatingsClimateHazard(yearCategory.code, yearCategory.label)">{{ yearCategory.label }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="col text-right">
                            <b-button 
                            variant="outline-secondary"
                            size="sm"
                            v-b-popover.hover.top="'Program-level summary of risk ratings associated with each key climate change hazard.'" >
                                <i class="fas fa-question-circle"></i>
                            </b-button>
                        </div>
                    </div>
                    <div id="riskRatingsAssetElementChart">
                        <BarChart
                            v-if="riskRatingsClimateHazardLoadFlag"
                            :chart-data="riskRatingsClimateHazardData"
                            :options="riskRatingsClimateHazardDataOptions"
                            :height="200"/>
                            <div v-else>
                                Data Loading...
                            </div>
                    </div>
                    
                </b-card>
            </div>
            <div class="col-4">
                <b-card>
                    <div class="row">
                        <div class="col text-right">
                            <b-button 
                            variant="outline-secondary"
                            size="sm"
                            v-b-popover.hover.top="'Number of risks across Coffs Harbour Bypass project and their ratings before and after adaptation'" >
                                <i class="fas fa-question-circle"></i>
                            </b-button>
                        </div>
                    </div>
                    <div id="totalRisksPrePostAdaptationChart">
                        <BarChart
                            v-if="totalRisksPrePostAdaptationLoadFlag"
                            :chart-data="totalRisksPrePostAdaptationData"
                            :options="totalRisksPrePostAdaptationDataOptions"
                            :height="200"/>
                        <div v-else>
                            Data Loading...
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import internal from 'assert'
import BarChart from './charts/BarChart'
import DoughnutChart from './charts/DoughnutChart'
import PieChart from './charts/PieChart'

export default {
  name: 'ProgramReportingDashboard',
  components: {
    BarChart,
    DoughnutChart,
    PieChart
  },
  data() {
    return {
        proportionProjectsCRALoadFlag: false,
        proportionProjectsCRAData: {},
        proportionProjectsCRADataOptions: {
            title: {
                text: "Current CRA status of Coffs Harbour Bypass",
                display: true,
                fontFamily: "Segoe UI",
                fontSize: 20
            },
            legend: {
                display: true,
                position: "left",
                labels: {
                    fontStyle: 'bold',
                    fontFamily: 'Segoe UI'
                }
            },
        },
        totalNumberOfAdaptations: {},
        totalNumberOfAdaptationsClass:"",
        totalProgramRiskProfileOptions: {
            title: {
                text: "Total risk profile 2030, 2050 and 2090",
                display: true,
                fontFamily: "Segoe UI",
                fontSize: 20
            },
            legend: {
                display: true,
                position: "left",
                labels: {
                    fontStyle: 'bold',
                    fontFamily: 'Segoe UI'
                }
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data.datasets[tooltipItem[0]['datasetIndex']].label;
                    }
                }
            }
        },
        totalProgramRiskProfileFlag: false,
        totalProgramRiskProfileData: {
            labels: [
                'No Risk Rating set',
                'Low',
                'Medium',
                'High',
                'Very High'
            ],
            datasets:[
                {
                    backgroundColor: [
                        "#6c757d",
                        "#28a745",
                        "#ffc107",
                        "#FF9900",
                        "#dd4b39",
                    ],
                    data: [],
                    label: 2090
                },
                {
                    backgroundColor: [
                        "#6c757d",
                        "#28a745",
                        "#ffc107",
                        "#FF9900",
                        "#dd4b39",
                    ],
                    data: [],
                    label: 2050
                },
                {
                    backgroundColor: [
                        "#6c757d",
                        "#28a745",
                        "#ffc107",
                        "#FF9900",
                        "#dd4b39",
                    ],
                    data: [],
                    label: 2030
                },
            ],
        },
        yearCategories: [
            {
            code: "riskRatingNFCode",
            label: "2030"
            },
            {
            code: "riskRatingMFCode",
            label: "2050"
            },
            {
            code: "riskRatingFFCode",
            label: "2090"
            },
        ],
        seletedYearCategory: "riskRatingNFCode",
        assetYearDropdownText: "2030",
        
        riskRatingsAssetElementDataOptions: {
            scales: {
                yAxes: [
                    {
                        display: false,
                        ticks: {
                                beginAtZero: true,
                                // min: -20,
                                stepSize: 1
                        },
                        stacked: true
                    }
                ],
                xAxes: [
                    {
                        stacked: true,
                        barPercentage: 0.7
                    }
                ]
            },
            title: {
                text: "Risk ratings by asset component",
                display: true,
                fontFamily: "Segoe UI",
                fontSize: 20
            },
            legend: {
                display: true,
                position: "left",
                labels: {
                    fontStyle: 'bold',
                    fontFamily: 'Segoe UI'
                }
            },
            tooltips: {
                enabled: true,
                // callbacks: {
                //     footer: function(tooltipItem, data) {
                //         const sum = 0
                //         console.log(data.datasets[tooltipItem[0]['datasetIndex']]);
                //         console.log(data.datasets);
                //         return data.datasets[tooltipItem[0]['datasetIndex']].label;
                //     }
                // }
            }
        },
        riskRatingsAssetElementLoadFlag: false,
        riskRatingsAssetElementData: {
            datasets: [
                {
                    label: 'No Rating',
                    data: [],
                    backgroundColor: "#6c757d",
                    // stack: 'Year',
                },
                {
                    label: 'Low',
                    data: [],
                    backgroundColor: "#28a745",
                    // stack: 'Year',
                },
                {
                    label: 'Medium',
                    data: [],
                    backgroundColor: "#ffc107",
                    // stack: 'Year',
                },
                {
                    label: 'High',
                    data: [],
                    backgroundColor: "#FF9900",
                    // stack: 'Year',
                },
                {
                    label: 'Very High',
                    data: [],
                    backgroundColor: "#dd4b39",
                    // stack: 'Year',
                },
            ],
            labels: [
                'Pavements',
                'Bridges and Viaducts',
                'Drainage Structures',
                'Retaining Walls',
                'Road furniture',
                'Noise Walls',
                'Control systems',
                'Street and Road Lighting',
                'Utilities',
                'Landscaping',
                'Embankments',
                'Construction Site',
                'Buildings',
                'Operational Objectives'
            ]
        },
        climateHazardYearDropdownText: "2030",
        riskRatingsClimateHazardDataOptions: {
            scales: {
                yAxes: [
                    {
                        display: false,
                        ticks: {
                                beginAtZero: true,
                                // min: -20,
                                stepSize: 1
                        },
                        stacked: true
                    }
                ],
                xAxes: [
                    {
                        stacked: true,
                        barPercentage: 0.7
                    }
                ]
            },
            title: {
                text: "Risk ratings by climate hazard",
                display: true,
                fontFamily: "Segoe UI",
                fontSize: 20
            },
            legend: {
                display: true,
                position: "left",
                labels: {
                    fontStyle: 'bold',
                    fontFamily: 'Segoe UI'
                }
            },
            tooltips: {
                enabled: true,
                // callbacks: {
                //     footer: function(tooltipItem, data) {
                //         const sum = 0
                //         console.log(data.datasets[tooltipItem[0]['datasetIndex']]);
                //         console.log(data.datasets);
                //         return data.datasets[tooltipItem[0]['datasetIndex']].label;
                //     }
                // }
            }
        },
        riskRatingsClimateHazardLoadFlag: false,
        riskRatingsClimateHazardData: {
            datasets: [
                {
                    label: 'No Rating',
                    data: [],
                    backgroundColor: "#6c757d",
                    // stack: 'Year',
                },
                {
                    label: 'Low',
                    data: [],
                    backgroundColor: "#28a745",
                    // stack: 'Year',
                },
                {
                    label: 'Medium',
                    data: [],
                    backgroundColor: "#ffc107",
                    // stack: 'Year',
                },
                {
                    label: 'High',
                    data: [],
                    backgroundColor: "#FF9900",
                    // stack: 'Year',
                },
                {
                    label: 'Very High',
                    data: [],
                    backgroundColor: "#dd4b39",
                    // stack: 'Year',
                },
            ],
            labels: [
                'Heatwaves',
                'Drought periods',
                'Maximum temperatures',
                'Compounded extreme events',
                'Flooding',
                'Bushfire weather',
                'Extreme wind',
                'Rainfall intensity',
                'Sea level rise and storm surge'
            ]
        },
        totalRisksPrePostAdaptationDataOptions: {
            scales: {
            yAxes: [
                {
                display: false,
                ticks: {
                    beginAtZero: true,
                    // min: -20,
                    stepSize: 1
                },
                stacked: true
                }
            ],
            xAxes: [
                {
                    stacked: true,
                    barPercentage: 0.7
                }
            ]
            },
            title: {
                text: "Total risks pre and post adaptation ",
                display: true,
                fontFamily: "Segoe UI",
                fontSize: 20
            },
            legend: {
            display: true,
            position: "left",
            labels: {
                fontStyle: 'bold',
                fontFamily: 'Segoe UI'
            }
            },
            // responsive: true,
            // maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                // callbacks: {
                //   label: function(tooltipItem, data) {
                //     return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
                //   }
                // }
            }
        },
        totalRisksPrePostAdaptationLoadFlag: false,
        totalRisksPrePostAdaptationData: {
            datasets: [
                {
                    label: 'Low',
                    data: [20,50,10],
                    backgroundColor: "rgba(40, 167, 69, 0.5)",
                    stack: 'Year',
                },
                {
                    label: 'Medium',
                    data: [20,50,10],
                    backgroundColor: "rgba(255, 193, 7, 0.5)",
                    stack: 'Year',
                },
                {
                    label: "High",
                    backgroundColor: "rgba(255, 153, 0, 0.5)",
                    data: [20,50,10],
                    stack: 'Year'
                },
                {
                    label: "Very High",
                    backgroundColor: "rgba(221, 75, 57, 0.5)",
                    data: [20,50,10],
                    stack: 'Year'
                },
                {
                    label: 'Residual Low',
                    data: [25,40,50],
                    backgroundColor: "#28a745",
                    stack: 'Residual',
                },
                {
                    label: 'Residual Medium',
                    data: [25,40,50],
                    backgroundColor: "#ffc107",
                    stack: 'Residual',
                },
                {
                    label: "Residual High",
                    backgroundColor: "#FF9900",
                    data: [25,40,50],
                    stack: 'Residual'
                },
                {
                    label: "Residual Very High",
                    backgroundColor: "#dd4b39",
                    data: [25,40,50],
                    stack: 'Residual'
                },
            ],
            labels: [
                "2030",
                "2050",
                "2090",
            ],
        },
        totalRisksPrePostAdaptationChartLabels: [
            "Low",
            "Medium",
            "High",
            "Very High",
            "Residual Low",
            "Residual Medium",
            "Residual High",
            "Residual Very High"
        ],
        barChartData: [],
        testInputData: [],
        testInputConfig: {
            backgroundColor: "teal"
        },
        barChart2Data: {
            datasets:[],
            labels: []
        },
        dashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=31c05dc6-38ac-49bd-8519-511addd108a8&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false'
    }
  },
  computed: {
    projectList: {
        get() {
            return this.$store.state.mainModule.projectList
        } 
    },
    craEntries: {
        get() {
            return this.$store.state.mapModule.craEntryList
        }
    }
  },
  watch: {
    projectList: function() {
        if(this.projectList.length) {
            console.log("went to watch projectList")
            this.fetchProportionProjectsCRAData()
        }
    },
    craEntries: function() {
        if(this.craEntries.length) {
            this.fetchTotalNumberOfAdaptationsImplemented()
            this.fetchTotalProgramRiskProfile()
            this.fetchRiskRatingsAssetElement(this.seletedYearCategory ,this.assetYearDropdownText)
            this.fetchRiskRatingsClimateHazard(this.seletedYearCategory ,this.climateHazardYearDropdownText)
            this.fetchTotalRisksPrePostAdaptation()
        }
    }
  },
  beforeMount() {
    var dataset = {
      label:"Sample",
      backgroundColor: "teal",
      data:[],
    }
    for (var i = 0; i < 10; i++) {
      dataset.data.push(Math.floor(Math.random()*100))
      this.barChart2Data.labels.push(i)
    }
    this.barChart2Data.datasets.push(dataset)
  },
  mounted() {
    this.fetchProportionProjectsCRAData()
    if (this.craEntries.length) {
        this.fetchTotalNumberOfAdaptationsImplemented()
        this.fetchTotalProgramRiskProfile()
        this.fetchRiskRatingsAssetElement(this.seletedYearCategory ,this.assetYearDropdownText)
        this.fetchRiskRatingsClimateHazard(this.seletedYearCategory ,this.climateHazardYearDropdownText)
        this.fetchTotalRisksPrePostAdaptation()
    }
  },
  methods: {
    fetchProportionProjectsCRAData: function() {
        this.proportionProjectsCRALoadFlag = false  
        var countProjectsTotal = 0
        var countProjectsStartedCCRA = 0
        var countProjectsCompletedCCRA = 0
        countProjectsTotal = this.projectList.length;
        this.projectList.forEach(item=>{
            if (item.stage_1_status=='completed' && item.stage_2_status=='completed' && item.stage_3_status=='completed' && item.stage_4_status=='completed') {
                countProjectsCompletedCCRA += item.stage_1_status=='completed' && item.stage_2_status=='completed' && item.stage_3_status=='completed' && item.stage_4_status=='completed'
            }
            else (
                countProjectsStartedCCRA += (item.stage_1_status=='completed'||item.stage_1_status=='in_progress') || (item.stage_2_status=='completed'||item.stage_2_status=='in_progress') || (item.stage_3_status=='completed'||item.stage_3_status=='in_progress') || (item.stage_4_status=='completed'||item.stage_4_status=='in_progress')
            )
        })
        var countProjectsNotStarted = countProjectsTotal - (countProjectsStartedCCRA + countProjectsCompletedCCRA)
        this.proportionProjectsCRAData = {
          datasets:[
            {
              fill: true,
              label: "",
              backgroundColor: ["#dd4b39","#FF9900","#28a745"],
              data: [countProjectsNotStarted, countProjectsStartedCCRA, countProjectsCompletedCCRA]
              // data: [0,0,0]
            }
          ],
          labels: [
            "Not Started",
            "In Progress",
            "Completed"
          ],
        }
        this.proportionProjectsCRALoadFlag = true  
    },
    fetchTotalNumberOfAdaptationsImplemented: function() {
        let adaptationItemsObject = {
            design: {
                adaptationActionTotal: 0,
                adaptationActionImplemented: 0,
                adaptationActionImplementedPercentage: 0,
                label: "Design"
            },
            construction: {
                adaptationActionTotal: 0,
                adaptationActionImplemented: 0,
                adaptationActionImplementedPercentage: 0,
                label: "Construction"
            },
            '2030': {
                adaptationActionTotal: 0,
                adaptationActionImplemented: 0,
                adaptationActionImplementedPercentage: 0,
                label: "2030"
            },
            '2050': {
                adaptationActionTotal: 0,
                adaptationActionImplemented: 0,
                adaptationActionImplementedPercentage: 0,
                label: "2050"
            },
            '2070': {
                adaptationActionTotal: 0,
                adaptationActionImplemented: 0,
                adaptationActionImplementedPercentage: 0,
                label: "2070"
            },
            '2090': {
                adaptationActionTotal: 0,
                adaptationActionImplemented: 0,
                adaptationActionImplementedPercentage: 0,
                label: "2090"
            }
        }
        let overallCRAEntries = this.craEntries.map(a=>a.entries).flat(1).map(obj=>obj.adaptationActions).flat(1)
        overallCRAEntries.forEach(item=>{
        if (item.implementation_timing=='Design') {
            adaptationItemsObject.design.adaptationActionTotal += 1
            if (item.is_implemented==1) {
                adaptationItemsObject.design.adaptationActionImplemented += 1
            }
        }
        else if (item.implementation_timing=='Construction') {
            adaptationItemsObject.construction.adaptationActionTotal += 1
            if (item.is_implemented==1) {
                adaptationItemsObject.construction.adaptationActionImplemented += 1
            }
        }
        else if (item.implementation_timing=='2030') {
            adaptationItemsObject['2030'].adaptationActionTotal += 1
            if (item.is_implemented==1) {
                adaptationItemsObject['2030'].adaptationActionImplemented += 1
            }
        }
        else if (item.implementation_timing=='2050') {
            adaptationItemsObject['2050'].adaptationActionTotal += 1
            if (item.is_implemented==1) {
                adaptationItemsObject['2050'].adaptationActionImplemented += 1
            }
        }
        else if (item.implementation_timing=='2070') {
            adaptationItemsObject['2070'].adaptationActionTotal += 1
            if (item.is_implemented==1) {
                adaptationItemsObject['2070'].adaptationActionImplemented += 1
            }
        }
        else if (item.implementation_timing=='2090') {
            adaptationItemsObject['2090'].adaptationActionTotal += 1
            if (item.is_implemented==1) {
                adaptationItemsObject['2090'].adaptationActionImplemented += 1
            }
        }
        })
        Object.keys(adaptationItemsObject).forEach(adaptationItemObjectKey=>{
        let adaptationItemObject = adaptationItemsObject[adaptationItemObjectKey]
        if (adaptationItemObject.adaptationActionTotal > 0) {
            adaptationItemObject.adaptationActionImplementedPercentage = (adaptationItemObject.adaptationActionImplemented / adaptationItemObject.adaptationActionTotal) * 100;
            adaptationItemObject.adaptationActionImplementedPercentage.toFixed(2)
        }   
        else {
            adaptationItemObject.adaptationActionImplementedPercentage = 0
        }
        })
        console.log(adaptationItemsObject);
        this.totalNumberOfAdaptations = {...adaptationItemsObject}

    },
    fetchTotalProgramRiskProfile: function() {
        this.totalProgramRiskProfileFlag = false  
        var total2030RiskCount = 0;
        var total2050RiskCount = 0;
        var total2090RiskCount = 0;
        var low2030RiskCount = 0;
        var medium2030RiskCount = 0;
        var high2030RiskCount = 0;
        var veryHigh2030RiskCount = 0;
        var noData2030RiskCount = 0;
        var low2050RiskCount = 0;
        var medium2050RiskCount = 0;
        var high2050RiskCount = 0;
        var veryHigh2050RiskCount = 0;
        var noData2050RiskCount = 0;
        var low2090RiskCount = 0;
        var medium2090RiskCount = 0;
        var high2090RiskCount = 0;
        var veryHigh2090RiskCount = 0;
        var noData2090RiskCount = 0;
        this.craEntries.map(a=>a.entries).forEach(projectEntry=>{
            projectEntry.forEach(item=>{
                total2030RiskCount += 1
                total2050RiskCount += 1
                total2090RiskCount += 1
                switch(item.riskRatingNFCode) {
                    case 'low':
                        low2030RiskCount +=1
                        break;
                    case 'medium':
                        medium2030RiskCount += 1
                        break;
                    case 'high':
                        high2030RiskCount += 1
                        break;
                    case 'very_high':
                        veryHigh2030RiskCount += 1
                        break;
                    default:
                        noData2030RiskCount += 1
                }
                switch(item.riskRatingMFCode) {
                    case 'low':
                        low2050RiskCount +=1
                        break;
                    case 'medium':
                        medium2050RiskCount += 1
                        break;
                    case 'high':
                        high2050RiskCount += 1
                        break;
                    case 'very_high':
                        veryHigh2050RiskCount += 1
                        break;
                    default:
                        noData2050RiskCount += 1
                }
                switch(item.riskRatingFFCode) {
                    case 'low':
                        low2090RiskCount +=1
                        break;
                    case 'medium':
                        medium2090RiskCount += 1
                        break;
                    case 'high':
                        high2090RiskCount += 1
                        break;
                    case 'very_high':
                        veryHigh2090RiskCount += 1
                        break;
                    default:
                        noData2090RiskCount += 1
                }
            })
        })
        this.totalProgramRiskProfileData = {
            labels: [
                'No Risk Rating set',
                'Low',
                'Medium',
                'High',
                'Very High'
            ],
            datasets:[
                {
                    backgroundColor: [
                        "#6c757d",
                        "#28a745",
                        "#ffc107",
                        "#FF9900",
                        "#dd4b39",
                    ],
                    data: [noData2090RiskCount, low2090RiskCount, medium2090RiskCount, high2090RiskCount, veryHigh2090RiskCount],
                    label: 2090
                },
                {
                    backgroundColor: [
                        "#6c757d",
                        "#28a745",
                        "#ffc107",
                        "#FF9900",
                        "#dd4b39",
                    ],
                    data: [noData2050RiskCount, low2050RiskCount, medium2050RiskCount, high2050RiskCount, veryHigh2050RiskCount],
                    label: 2050
                },
                {
                    backgroundColor: [
                        "#6c757d",
                        "#28a745",
                        "#ffc107",
                        "#FF9900",
                        "#dd4b39",
                    ],
                    data: [noData2030RiskCount, low2030RiskCount, medium2030RiskCount, high2030RiskCount, veryHigh2030RiskCount],
                    label: 2030
                },
            ],
        }
        this.totalProgramRiskProfileFlag = true  
    },
    fetchRiskRatingsAssetElement: function(riskYearCode, riskYear) {
        this.riskRatingsAssetElementLoadFlag = false
        this.seletedYearCategory = riskYearCode
        this.assetYearDropdownText = riskYear
        var totalEntries = 0
        var totalPavements = 0
        var totalBridgesViaducts = 0
        var totalDrainage = 0
        var totalRetainingWalls = 0
        var totalRoadFurniture = 0
        var totalNoiseWalls = 0
        var totalControlSystems = 0
        var totalStreetLighting = 0
        var totalUtilities = 0
        var totalLandscaping = 0
        var totalEmbankments = 0
        var totalConstruction = 0
        var totalBuildings = 0
        var totalOperations = 0
        var totalLowEntries = 0
        var totalLowPavements = 0
        var totalLowBridgesViaducts = 0
        var totalLowDrainage = 0
        var totalLowRetainingWalls = 0
        var totalLowRoadFurniture = 0
        var totalLowNoiseWalls = 0
        var totalLowControlSystems = 0
        var totalLowStreetLighting = 0
        var totalLowUtilities = 0
        var totalLowLandscaping = 0
        var totalLowEmbankments = 0
        var totalLowConstruction = 0
        var totalLowBuildings = 0
        var totalLowOperations = 0
        var totalMediumEntries = 0
        var totalMediumPavements = 0
        var totalMediumBridgesViaducts = 0
        var totalMediumDrainage = 0
        var totalMediumRetainingWalls = 0
        var totalMediumRoadFurniture = 0
        var totalMediumNoiseWalls = 0
        var totalMediumControlSystems = 0
        var totalMediumStreetLighting = 0
        var totalMediumUtilities = 0
        var totalMediumLandscaping = 0
        var totalMediumEmbankments = 0
        var totalMediumConstruction = 0
        var totalMediumBuildings = 0
        var totalMediumOperations = 0
        var totalHighEntries = 0
        var totalHighPavements = 0
        var totalHighBridgesViaducts = 0
        var totalHighDrainage = 0
        var totalHighRetainingWalls = 0
        var totalHighRoadFurniture = 0
        var totalHighNoiseWalls = 0
        var totalHighControlSystems = 0
        var totalHighStreetLighting = 0
        var totalHighUtilities = 0
        var totalHighLandscaping = 0
        var totalHighEmbankments = 0
        var totalHighConstruction = 0
        var totalHighBuildings = 0
        var totalHighOperations = 0
        var totalVeryHighEntries = 0
        var totalVeryHighPavements = 0
        var totalVeryHighBridgesViaducts = 0
        var totalVeryHighDrainage = 0
        var totalVeryHighRetainingWalls = 0
        var totalVeryHighRoadFurniture = 0
        var totalVeryHighNoiseWalls = 0
        var totalVeryHighControlSystems = 0
        var totalVeryHighStreetLighting = 0
        var totalVeryHighUtilities = 0
        var totalVeryHighLandscaping = 0
        var totalVeryHighEmbankments = 0
        var totalVeryHighConstruction = 0
        var totalVeryHighBuildings = 0
        var totalVeryHighOperations = 0

        this.craEntries.map(a=>a.entries).forEach(projectEntry=>{
            // total
            
            totalEntries += projectEntry.length
            totalPavements += projectEntry.filter(obj => obj.primaryAssetTypeCode == "pavements").length           
            totalBridgesViaducts += projectEntry.filter(obj => obj.primaryAssetTypeCode == "bridges_and_viaducts").length
            totalDrainage += projectEntry.filter(obj => obj.primaryAssetTypeCode == "drainage").length
            totalRetainingWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "retaining_walls").length
            totalRoadFurniture += projectEntry.filter(obj => obj.primaryAssetTypeCode == "road_furniture").length
            totalNoiseWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "noise_walls").length
            totalControlSystems += projectEntry.filter(obj => obj.primaryAssetTypeCode == "control_systems").length
            totalStreetLighting += projectEntry.filter(obj => obj.primaryAssetTypeCode == "street_road_lighting").length
            totalUtilities += projectEntry.filter(obj => obj.primaryAssetTypeCode == "utilities").length
            totalLandscaping += projectEntry.filter(obj => obj.primaryAssetTypeCode == "landscaping").length
            totalEmbankments += projectEntry.filter(obj => obj.primaryAssetTypeCode == "embankments").length
            totalConstruction += projectEntry.filter(obj => obj.primaryAssetTypeCode == "construction").length
            totalBuildings += projectEntry.filter(obj => obj.primaryAssetTypeCode == "buildings").length
            totalOperations += projectEntry.filter(obj => obj.primaryAssetTypeCode == "operations").length

            // low
            totalLowEntries += projectEntry.filter(obj => obj[riskYearCode]=='low').length
            totalLowPavements += projectEntry.filter(obj => obj.primaryAssetTypeCode == "pavements" && obj[riskYearCode]=='low').length           
            totalLowBridgesViaducts += projectEntry.filter(obj => obj.primaryAssetTypeCode == "bridges_and_viaducts" && obj[riskYearCode]=='low').length
            totalLowDrainage += projectEntry.filter(obj => obj.primaryAssetTypeCode == "drainage" && obj[riskYearCode]=='low').length
            totalLowRetainingWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "retaining_walls" && obj[riskYearCode]=='low').length
            totalLowRoadFurniture += projectEntry.filter(obj => obj.primaryAssetTypeCode == "road_furniture" && obj[riskYearCode]=='low').length
            totalLowNoiseWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "noise_walls" && obj[riskYearCode]=='low').length
            totalLowControlSystems += projectEntry.filter(obj => obj.primaryAssetTypeCode == "control_systems" && obj[riskYearCode]=='low').length
            totalLowStreetLighting += projectEntry.filter(obj => obj.primaryAssetTypeCode == "street_road_lighting" && obj[riskYearCode]=='low').length
            totalLowUtilities += projectEntry.filter(obj => obj.primaryAssetTypeCode == "utilities" && obj[riskYearCode]=='low').length
            totalLowLandscaping += projectEntry.filter(obj => obj.primaryAssetTypeCode == "landscaping" && obj[riskYearCode]=='low').length
            totalLowEmbankments += projectEntry.filter(obj => obj.primaryAssetTypeCode == "embankments" && obj[riskYearCode]=='low').length
            totalLowConstruction += projectEntry.filter(obj => obj.primaryAssetTypeCode == "construction" && obj[riskYearCode]=='low').length
            totalLowBuildings += projectEntry.filter(obj => obj.primaryAssetTypeCode == "buildings" && obj[riskYearCode]=='low').length
            totalLowOperations += projectEntry.filter(obj => obj.primaryAssetTypeCode == "operations" && obj[riskYearCode]=='low').length

            // medium
            totalMediumEntries += projectEntry.filter(obj => obj[riskYearCode]=='medium').length
            totalMediumPavements += projectEntry.filter(obj => obj.primaryAssetTypeCode == "pavements" && obj[riskYearCode]=='medium').length           
            totalMediumBridgesViaducts += projectEntry.filter(obj => obj.primaryAssetTypeCode == "bridges_and_viaducts" && obj[riskYearCode]=='medium').length
            totalMediumDrainage += projectEntry.filter(obj => obj.primaryAssetTypeCode == "drainage" && obj[riskYearCode]=='medium').length
            totalMediumRetainingWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "retaining_walls" && obj[riskYearCode]=='medium').length
            totalMediumRoadFurniture += projectEntry.filter(obj => obj.primaryAssetTypeCode == "road_furniture" && obj[riskYearCode]=='medium').length
            totalMediumNoiseWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "noise_walls" && obj[riskYearCode]=='medium').length
            totalMediumControlSystems += projectEntry.filter(obj => obj.primaryAssetTypeCode == "control_systems" && obj[riskYearCode]=='medium').length
            totalMediumStreetLighting += projectEntry.filter(obj => obj.primaryAssetTypeCode == "street_road_lighting" && obj[riskYearCode]=='medium').length
            totalMediumUtilities += projectEntry.filter(obj => obj.primaryAssetTypeCode == "utilities" && obj[riskYearCode]=='medium').length
            totalMediumLandscaping += projectEntry.filter(obj => obj.primaryAssetTypeCode == "landscaping" && obj[riskYearCode]=='medium').length
            totalMediumEmbankments += projectEntry.filter(obj => obj.primaryAssetTypeCode == "embankments" && obj[riskYearCode]=='medium').length
            totalMediumConstruction += projectEntry.filter(obj => obj.primaryAssetTypeCode == "construction" && obj[riskYearCode]=='medium').length
            totalMediumBuildings += projectEntry.filter(obj => obj.primaryAssetTypeCode == "buildings" && obj[riskYearCode]=='medium').length
            totalMediumOperations += projectEntry.filter(obj => obj.primaryAssetTypeCode == "operations" && obj[riskYearCode]=='medium').length

            // high
            totalHighEntries += projectEntry.filter(obj => obj[riskYearCode]=='high').length
            totalHighPavements += projectEntry.filter(obj => obj.primaryAssetTypeCode == "pavements" && obj[riskYearCode]=='high').length           
            totalHighBridgesViaducts += projectEntry.filter(obj => obj.primaryAssetTypeCode == "bridges_and_viaducts" && obj[riskYearCode]=='high').length
            totalHighDrainage += projectEntry.filter(obj => obj.primaryAssetTypeCode == "drainage" && obj[riskYearCode]=='high').length
            totalHighRetainingWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "retaining_walls" && obj[riskYearCode]=='high').length
            totalHighRoadFurniture += projectEntry.filter(obj => obj.primaryAssetTypeCode == "road_furniture" && obj[riskYearCode]=='high').length
            totalHighNoiseWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "noise_walls" && obj[riskYearCode]=='high').length
            totalHighControlSystems += projectEntry.filter(obj => obj.primaryAssetTypeCode == "control_systems" && obj[riskYearCode]=='high').length
            totalHighStreetLighting += projectEntry.filter(obj => obj.primaryAssetTypeCode == "street_road_lighting" && obj[riskYearCode]=='high').length
            totalHighUtilities += projectEntry.filter(obj => obj.primaryAssetTypeCode == "utilities" && obj[riskYearCode]=='high').length
            totalHighLandscaping += projectEntry.filter(obj => obj.primaryAssetTypeCode == "landscaping" && obj[riskYearCode]=='high').length
            totalHighEmbankments += projectEntry.filter(obj => obj.primaryAssetTypeCode == "embankments" && obj[riskYearCode]=='high').length
            totalHighConstruction += projectEntry.filter(obj => obj.primaryAssetTypeCode == "construction" && obj[riskYearCode]=='high').length
            totalHighBuildings += projectEntry.filter(obj => obj.primaryAssetTypeCode == "buildings" && obj[riskYearCode]=='high').length
            totalHighOperations += projectEntry.filter(obj => obj.primaryAssetTypeCode == "operations" && obj[riskYearCode]=='high').length

            // very_high
            totalVeryHighEntries += projectEntry.filter(obj => obj[riskYearCode]=='very_high').length
            totalVeryHighPavements += projectEntry.filter(obj => obj.primaryAssetTypeCode == "pavements" && obj[riskYearCode]=='very_high').length           
            totalVeryHighBridgesViaducts += projectEntry.filter(obj => obj.primaryAssetTypeCode == "bridges_and_viaducts" && obj[riskYearCode]=='very_high').length
            totalVeryHighDrainage += projectEntry.filter(obj => obj.primaryAssetTypeCode == "drainage" && obj[riskYearCode]=='very_high').length
            totalVeryHighRetainingWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "retaining_walls" && obj[riskYearCode]=='very_high').length
            totalVeryHighRoadFurniture += projectEntry.filter(obj => obj.primaryAssetTypeCode == "road_furniture" && obj[riskYearCode]=='very_high').length
            totalVeryHighNoiseWalls += projectEntry.filter(obj => obj.primaryAssetTypeCode == "noise_walls" && obj[riskYearCode]=='very_high').length
            totalVeryHighControlSystems += projectEntry.filter(obj => obj.primaryAssetTypeCode == "control_systems" && obj[riskYearCode]=='very_high').length
            totalVeryHighStreetLighting += projectEntry.filter(obj => obj.primaryAssetTypeCode == "street_road_lighting" && obj[riskYearCode]=='very_high').length
            totalVeryHighUtilities += projectEntry.filter(obj => obj.primaryAssetTypeCode == "utilities" && obj[riskYearCode]=='very_high').length
            totalVeryHighLandscaping += projectEntry.filter(obj => obj.primaryAssetTypeCode == "landscaping" && obj[riskYearCode]=='very_high').length
            totalVeryHighEmbankments += projectEntry.filter(obj => obj.primaryAssetTypeCode == "embankments" && obj[riskYearCode]=='very_high').length
            totalVeryHighConstruction += projectEntry.filter(obj => obj.primaryAssetTypeCode == "construction" && obj[riskYearCode]=='very_high').length
            totalVeryHighBuildings += projectEntry.filter(obj => obj.primaryAssetTypeCode == "buildings" && obj[riskYearCode]=='very_high').length
            totalVeryHighOperations += projectEntry.filter(obj => obj.primaryAssetTypeCode == "operations" && obj[riskYearCode]=='very_high').length
        })
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.length = 0
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.length = 0
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.length = 0
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.length = 0
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.length = 0


        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalPavements - (totalLowPavements+totalMediumPavements+totalHighPavements+totalVeryHighPavements))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalBridgesViaducts - (totalLowBridgesViaducts+totalMediumBridgesViaducts+totalHighBridgesViaducts+totalVeryHighBridgesViaducts))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalDrainage - (totalLowDrainage+totalMediumDrainage+totalHighDrainage+totalVeryHighDrainage))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalRetainingWalls - (totalLowRetainingWalls+totalMediumRetainingWalls+totalHighRetainingWalls+totalVeryHighRetainingWalls))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalRoadFurniture - (totalLowRoadFurniture+totalMediumRoadFurniture+totalHighRoadFurniture+totalVeryHighRoadFurniture))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalNoiseWalls - (totalLowNoiseWalls+totalMediumNoiseWalls+totalHighNoiseWalls+totalVeryHighNoiseWalls))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalControlSystems - (totalLowControlSystems+totalMediumControlSystems+totalHighControlSystems+totalVeryHighControlSystems))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalStreetLighting - (totalLowStreetLighting+totalMediumStreetLighting+totalHighStreetLighting+totalVeryHighStreetLighting))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalUtilities - (totalLowUtilities+totalMediumUtilities+totalHighUtilities+totalVeryHighUtilities))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalLandscaping - (totalLowLandscaping+totalMediumLandscaping+totalHighLandscaping+totalVeryHighLandscaping))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalEmbankments - (totalLowEmbankments+totalMediumEmbankments+totalHighEmbankments+totalVeryHighEmbankments))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalConstruction - (totalLowConstruction+totalMediumConstruction+totalHighConstruction+totalVeryHighConstruction))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalBuildings - (totalLowBuildings+totalMediumBuildings+totalHighBuildings+totalVeryHighBuildings))
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "No Rating").data.push(totalOperations - (totalLowOperations+totalMediumOperations+totalHighOperations+totalVeryHighOperations))

        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowPavements)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowBridgesViaducts)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowDrainage)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowRetainingWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowRoadFurniture)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowNoiseWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowControlSystems)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowStreetLighting)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowUtilities)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowLandscaping)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowEmbankments)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowConstruction)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowBuildings)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Low").data.push(totalLowOperations)

        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumPavements)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumBridgesViaducts)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumDrainage)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumRetainingWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumRoadFurniture)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumNoiseWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumControlSystems)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumStreetLighting)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumUtilities)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumLandscaping)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumEmbankments)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumConstruction)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumBuildings)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumOperations)

        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighPavements)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighBridgesViaducts)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighDrainage)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighRetainingWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighRoadFurniture)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighNoiseWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighControlSystems)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighStreetLighting)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighUtilities)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighLandscaping)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighEmbankments)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighConstruction)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighBuildings)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "High").data.push(totalHighOperations)
        
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighPavements)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighBridgesViaducts)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighDrainage)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighRetainingWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighRoadFurniture)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighNoiseWalls)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighControlSystems)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighStreetLighting)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighUtilities)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighLandscaping)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighEmbankments)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighConstruction)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighBuildings)
        this.riskRatingsAssetElementData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighOperations)
        const current = this
        setTimeout(function() {
            console.log("delay triggered")
            current.riskRatingsAssetElementLoadFlag = true
        }, 200);
    },
    fetchRiskRatingsClimateHazard: function(riskYearCode, riskYear) {
        this.riskRatingsClimateHazardLoadFlag = false
        this.seletedYearCategory = riskYearCode
        this.assetYearDropdownText = riskYear
        var totalEntries = 0
        var totalHeatwaves = 0
        var totalDrought = 0
        var totalMaximumTemperatures = 0
        var totalExtremeEvents = 0
        var totalFlooding = 0
        var totalBushfire = 0
        var totalWind = 0
        var totalRainfall = 0
        var totalSeaLevelStormSurge = 0

        var totalLowEntries = 0
        var totalLowHeatwaves = 0
        var totalLowDrought = 0
        var totalLowMaximumTemperatures = 0
        var totalLowExtremeEvents = 0
        var totalLowFlooding = 0
        var totalLowBushfire = 0
        var totalLowWind = 0
        var totalLowRainfall = 0
        var totalLowSeaLevelStormSurge = 0

        var totalMediumEntries = 0
        var totalMediumHeatwaves = 0
        var totalMediumDrought = 0
        var totalMediumMaximumTemperatures = 0
        var totalMediumExtremeEvents = 0
        var totalMediumFlooding = 0
        var totalMediumBushfire = 0
        var totalMediumWind = 0
        var totalMediumRainfall = 0
        var totalMediumSeaLevelStormSurge = 0

        var totalHighEntries = 0
        var totalHighHeatwaves = 0
        var totalHighDrought = 0
        var totalHighMaximumTemperatures = 0
        var totalHighExtremeEvents = 0
        var totalHighFlooding = 0
        var totalHighBushfire = 0
        var totalHighWind = 0
        var totalHighRainfall = 0
        var totalHighSeaLevelStormSurge = 0

        var totalVeryHighEntries = 0
        var totalVeryHighHeatwaves = 0
        var totalVeryHighDrought = 0
        var totalVeryHighMaximumTemperatures = 0
        var totalVeryHighExtremeEvents = 0
        var totalVeryHighFlooding = 0
        var totalVeryHighBushfire = 0
        var totalVeryHighWind = 0
        var totalVeryHighRainfall = 0
        var totalVeryHighSeaLevelStormSurge = 0
        

        this.craEntries.map(a=>a.entries).forEach(projectEntry=>{
            // total
            
            totalEntries += projectEntry.length
            totalHeatwaves += projectEntry.filter(obj => obj.climateHazardCode == "heatwaves").length 
            totalDrought += projectEntry.filter(obj => obj.climateHazardCode == "drought").length 
            totalMaximumTemperatures += projectEntry.filter(obj => obj.climateHazardCode == "maximum_temperatures").length 
            totalExtremeEvents += projectEntry.filter(obj => obj.climateHazardCode == "extreme_events").length 
            totalFlooding += projectEntry.filter(obj => obj.climateHazardCode == "flooding").length 
            totalBushfire += projectEntry.filter(obj => obj.climateHazardCode == "bushfire").length 
            totalWind += projectEntry.filter(obj => obj.climateHazardCode == "wind").length 
            totalRainfall += projectEntry.filter(obj => obj.climateHazardCode == "rainfall").length 
            totalSeaLevelStormSurge += projectEntry.filter(obj => obj.climateHazardCode == "sea_level_storm_surge").length 

            // low
            totalLowEntries += projectEntry.filter(obj => obj[riskYearCode]=='low').length
            totalLowHeatwaves += projectEntry.filter(obj => obj.climateHazardCode == "heatwaves" && obj[riskYearCode]=='low').length 
            totalLowDrought += projectEntry.filter(obj => obj.climateHazardCode == "drought" && obj[riskYearCode]=='low').length 
            totalLowMaximumTemperatures += projectEntry.filter(obj => obj.climateHazardCode == "maximum_temperatures" && obj[riskYearCode]=='low').length 
            totalLowExtremeEvents += projectEntry.filter(obj => obj.climateHazardCode == "extreme_events" && obj[riskYearCode]=='low').length 
            totalLowFlooding += projectEntry.filter(obj => obj.climateHazardCode == "flooding" && obj[riskYearCode]=='low').length 
            totalLowBushfire += projectEntry.filter(obj => obj.climateHazardCode == "bushfire" && obj[riskYearCode]=='low').length 
            totalLowWind += projectEntry.filter(obj => obj.climateHazardCode == "wind" && obj[riskYearCode]=='low').length 
            totalLowRainfall += projectEntry.filter(obj => obj.climateHazardCode == "rainfall" && obj[riskYearCode]=='low').length 
            totalLowSeaLevelStormSurge += projectEntry.filter(obj => obj.climateHazardCode == "sea_level_storm_surge" && obj[riskYearCode]=='low').length

            
            

            // medium
            totalMediumEntries += projectEntry.filter(obj => obj[riskYearCode]=='medium').length
            totalMediumHeatwaves += projectEntry.filter(obj => obj.climateHazardCode == "heatwaves" && obj[riskYearCode]=='medium').length 
            totalMediumDrought += projectEntry.filter(obj => obj.climateHazardCode == "drought" && obj[riskYearCode]=='medium').length 
            totalMediumMaximumTemperatures += projectEntry.filter(obj => obj.climateHazardCode == "maximum_temperatures" && obj[riskYearCode]=='medium').length 
            totalMediumExtremeEvents += projectEntry.filter(obj => obj.climateHazardCode == "extreme_events" && obj[riskYearCode]=='medium').length 
            totalMediumFlooding += projectEntry.filter(obj => obj.climateHazardCode == "flooding" && obj[riskYearCode]=='medium').length 
            totalMediumBushfire += projectEntry.filter(obj => obj.climateHazardCode == "bushfire" && obj[riskYearCode]=='medium').length 
            totalMediumWind += projectEntry.filter(obj => obj.climateHazardCode == "wind" && obj[riskYearCode]=='medium').length 
            totalMediumRainfall += projectEntry.filter(obj => obj.climateHazardCode == "rainfall" && obj[riskYearCode]=='medium').length 
            totalMediumSeaLevelStormSurge += projectEntry.filter(obj => obj.climateHazardCode == "sea_level_storm_surge" && obj[riskYearCode]=='medium').length

            // high
            totalHighEntries += projectEntry.filter(obj => obj[riskYearCode]=='high').length
            totalHighHeatwaves += projectEntry.filter(obj => obj.climateHazardCode == "heatwaves" && obj[riskYearCode]=='high').length 
            totalHighDrought += projectEntry.filter(obj => obj.climateHazardCode == "drought" && obj[riskYearCode]=='high').length 
            totalHighMaximumTemperatures += projectEntry.filter(obj => obj.climateHazardCode == "maximum_temperatures" && obj[riskYearCode]=='high').length 
            totalHighExtremeEvents += projectEntry.filter(obj => obj.climateHazardCode == "extreme_events" && obj[riskYearCode]=='high').length 
            totalHighFlooding += projectEntry.filter(obj => obj.climateHazardCode == "flooding" && obj[riskYearCode]=='high').length 
            totalHighBushfire += projectEntry.filter(obj => obj.climateHazardCode == "bushfire" && obj[riskYearCode]=='high').length 
            totalHighWind += projectEntry.filter(obj => obj.climateHazardCode == "wind" && obj[riskYearCode]=='high').length 
            totalHighRainfall += projectEntry.filter(obj => obj.climateHazardCode == "rainfall" && obj[riskYearCode]=='high').length 
            totalHighSeaLevelStormSurge += projectEntry.filter(obj => obj.climateHazardCode == "sea_level_storm_surge" && obj[riskYearCode]=='high').length

            // very_high
            totalVeryHighEntries += projectEntry.filter(obj => obj[riskYearCode]=='very_high').length
            totalVeryHighHeatwaves += projectEntry.filter(obj => obj.climateHazardCode == "heatwaves" && obj[riskYearCode]=='very_high').length 
            totalVeryHighDrought += projectEntry.filter(obj => obj.climateHazardCode == "drought" && obj[riskYearCode]=='very_high').length 
            totalVeryHighMaximumTemperatures += projectEntry.filter(obj => obj.climateHazardCode == "maximum_temperatures" && obj[riskYearCode]=='very_high').length 
            totalVeryHighExtremeEvents += projectEntry.filter(obj => obj.climateHazardCode == "extreme_events" && obj[riskYearCode]=='very_high').length 
            totalVeryHighFlooding += projectEntry.filter(obj => obj.climateHazardCode == "flooding" && obj[riskYearCode]=='very_high').length 
            totalVeryHighBushfire += projectEntry.filter(obj => obj.climateHazardCode == "bushfire" && obj[riskYearCode]=='very_high').length 
            totalVeryHighWind += projectEntry.filter(obj => obj.climateHazardCode == "wind" && obj[riskYearCode]=='very_high').length 
            totalVeryHighRainfall += projectEntry.filter(obj => obj.climateHazardCode == "rainfall" && obj[riskYearCode]=='very_high').length 
            totalVeryHighSeaLevelStormSurge += projectEntry.filter(obj => obj.climateHazardCode == "sea_level_storm_surge" && obj[riskYearCode]=='very_high').length

        })
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.length = 0
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.length = 0
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.length = 0
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.length = 0
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.length = 0

        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalHeatwaves- (totalLowHeatwaves+totalMediumHeatwaves+totalHighHeatwaves+totalVeryHighHeatwaves))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalDrought- (totalLowDrought+totalMediumDrought+totalHighDrought+totalVeryHighDrought))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalMaximumTemperatures- (totalLowMaximumTemperatures+totalMediumMaximumTemperatures+totalHighMaximumTemperatures+totalVeryHighMaximumTemperatures))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalExtremeEvents- (totalLowExtremeEvents+totalMediumExtremeEvents+totalHighExtremeEvents+totalVeryHighExtremeEvents))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalFlooding- (totalLowFlooding+totalMediumFlooding+totalHighFlooding+totalVeryHighFlooding))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalBushfire- (totalLowBushfire+totalMediumBushfire+totalHighBushfire+totalVeryHighBushfire))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalWind- (totalLowWind+totalMediumWind+totalHighWind+totalVeryHighWind))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalRainfall- (totalLowRainfall+totalMediumRainfall+totalHighRainfall+totalVeryHighRainfall))
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "No Rating").data.push(totalSeaLevelStormSurge- (totalLowSeaLevelStormSurge+totalMediumSeaLevelStormSurge+totalHighSeaLevelStormSurge+totalVeryHighSeaLevelStormSurge))

        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowHeatwaves)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowDrought)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowMaximumTemperatures)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowExtremeEvents)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowFlooding)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowBushfire)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowWind)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowRainfall)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Low").data.push(totalLowSeaLevelStormSurge)

        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumHeatwaves)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumDrought)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumMaximumTemperatures)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumExtremeEvents)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumFlooding)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumBushfire)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumWind)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumRainfall)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Medium").data.push(totalMediumSeaLevelStormSurge)

        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighHeatwaves)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighDrought)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighMaximumTemperatures)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighExtremeEvents)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighFlooding)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighBushfire)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighWind)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighRainfall)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "High").data.push(totalHighSeaLevelStormSurge)

        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighHeatwaves)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighDrought)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighMaximumTemperatures)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighExtremeEvents)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighFlooding)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighBushfire)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighWind)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighRainfall)
        this.riskRatingsClimateHazardData.datasets.find(obj => obj.label == "Very High").data.push(totalVeryHighSeaLevelStormSurge)


        
        const current = this
        setTimeout(function() {
            console.log("delay triggered")
            current.riskRatingsClimateHazardLoadFlag = true
        }, 200);
    },
    fetchTotalRisksPrePostAdaptation: function () {
        const current = this
        var consolidatedProjectRiskEntries = []
        // consolidate data
        current.craEntries.map(a=>a.entries).forEach(projectEntry=>{
            consolidatedProjectRiskEntries = consolidatedProjectRiskEntries.concat(projectEntry)
        })
        this.totalRisksPrePostAdaptationChartLabels.forEach((item,index)=>{
            if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Low" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Year"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingNFCode == "low")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingMFCode == "low")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingFFCode == "low")).length
            ]
          }
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Medium" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Year"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingNFCode == "medium")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingMFCode == "medium")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingFFCode == "medium")).length 
            ]
          }
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "High" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Year"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingNFCode == "high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingMFCode == "high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingFFCode == "high")).length 
            ]
          }
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Very High" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Year"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingNFCode == "very_high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingMFCode == "very_high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.riskRatingFFCode == "very_high")).length
            ]
          }
          // Residual
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Residual Low" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Residual"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingNFCode == "low" || (obj.riskRatingNFCode == "low" && obj.residualRiskRatingNFCode == ""))).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingMFCode == "low" || (obj.riskRatingNFCode == "low" && obj.residualRiskRatingNFCode == ""))).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingFFCode == "low" || (obj.riskRatingNFCode == "low" && obj.residualRiskRatingNFCode == ""))).length
            ]
          }
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Residual Medium" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Residual"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingNFCode == "medium")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingMFCode == "medium")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingFFCode == "medium")).length
            ]
          }
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Residual High" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Residual"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingNFCode == "high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingMFCode == "high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingFFCode == "high")).length
            ]
          }
          if(this.totalRisksPrePostAdaptationData.datasets[index].label == "Residual Very High" && this.totalRisksPrePostAdaptationData.datasets[index].stack == "Residual"){
            this.totalRisksPrePostAdaptationData.datasets[index].data = [
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingNFCode == "very_high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingMFCode == "very_high")).length,
              (consolidatedProjectRiskEntries.filter(obj => obj.residualRiskRatingFFCode == "very_high")).length
            ]
          }
        })

        setTimeout(function() {
            console.log("delay triggered")
            current.totalRisksPrePostAdaptationLoadFlag = true
        }, 200);
    }
  }
}
</script>

<style scoped>
 .programDashboard {
    max-height: 85vh!important;
 }
 .chartRow {
    height: 35vh!important;
 }
 .cardHeightText {
    height: 37.5vh!important;
 }
</style>