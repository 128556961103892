import Vue from "vue";
import Vuex from "vuex";

import mainStateModule from "./mainStateModule.js"
import mapStateModule from "./mapStateModule.js"

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  modules: {
    mapModule: mapStateModule,
    mainModule: mainStateModule
  },
  strict: debug
})