<template>
    <header class="appHeader">
        <nav class="navbar navbar-expand-lg bg-secondary navbar-dark">
            <div class="container-fluid">
                <a class="navbar-brand text-light" href="/">
                    <div class="row">
                        <div class="col">
                            <!-- <img src="img/ESC_WhOnTransparent.png" alt="Logo" height="60" class="mt-1"/> -->
                        </div>
                        <div class="col">
                        <h4 class="mb-0">Coffs Harbour Bypass Program</h4>
                            <small>Climate Change Risk Assessment Tool</small>
                        </div>
                    </div>
                </a>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <b-button variant="secondary" v-b-popover.hover.bottomleft="'Term Matrix'" @click="modalTermMatrixShow=true"><i class="fa fa-table"></i></b-button>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link active" href="#">Home</a> -->
                            <router-link to="/" class="nav-link" @click.native="setActiveTab('home')">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" href="#">Program Reporting Dashboard</a> -->
                            <router-link to="/programreportingdashboard" class="nav-link" @click.native="setActiveTab('pgrd')">Program Reporting Dashboard</router-link>
                            <!-- <a href="#link" class="btn btn-outline-info nav-link" role="button">Link Button</a> -->
                        </li>
                        <li class="nav-item">
                            <router-link to="/projectreportingdashboard" class="nav-link" @click.native="setActiveTab('pjrd')">Project Reporting Dashboard</router-link>

                            <!-- <a class="nav-link" v-on:click="openProjectRD()">Project Reporting Dashboard</a> -->
                        </li>
                    </ul>
                </div>
                <template v-if="projectId != null">
                    <form class="form-inline ml-3">
                        <div class="row">
                            <div class="col">
                                <span class="navbar-text">
                                    Stage 1: Pre-screening
                                </span>
                                <div class="row">
                                    <div class="col">
                                        <b-button-group size="sm">
                                            <b-button variant="outline-light" 
                                            :class="{buttonActiveCustom: stageTab['assetcontext'].isActive == true}" 
                                            @click="goToRoute('assetcontext')">
                                                Project context
                                            </b-button>
                                            <b-button variant="outline-light"
                                            :class="{buttonActiveCustom: stageTab['climatecontext'].isActive == true}" 
                                            @click="goToRoute('climatecontext')">
                                                Climate context
                                            </b-button>
                                        </b-button-group>         
                                    </div>
                                </div>
                            </div>
                            <span class="navbar-text mr-1 pt-4">
                                <i class="fa fa-arrow-right"></i>
                            </span>
                            <b-button variant="outline-light" size="sm"
                            :class="{buttonActiveCustom: stageTab['preliminaryassessment'].isActive == true}" 
                            @click="goToRoute('preliminaryassessment')">
                                Stage 2: <br/>Preliminary assessment
                            </b-button>
                            
                            <span class="navbar-text ml-1 mr-1 pt-4">
                                <i class="fa fa-arrow-right"></i>
                            </span>
                            <b-button variant="outline-light" size="sm"
                             :class="{buttonActiveCustom: stageTab['detailedassessment'].isActive == true}"
                             @click="goToRoute('detailedassessment')">
                                Stage 3: <br/>Detailed assessment
                            </b-button>
                            <span class="navbar-text ml-1 mr-1 pt-4">
                                <i class="fa fa-arrow-right"></i>
                            </span>
                            <b-button variant="outline-light" size="sm"
                            :class="{buttonActiveCustom: stageTab['adaptationplanning'].isActive == true}"
                            @click="goToRoute('adaptationplanning')">
                                Stage 4: <br/>Adaptation planning
                            </b-button>
                            <!-- <div class="col">
                                <b-button variant="outline-light" sm class="my-sm-0 ">
                                    Stage 2: Preliminary assessment
                                </b-button>
                            </div> -->
                        </div>
                        
                        
                    </form>
                </template>
            </div>
        </nav>
        <!-- <nav class="navbar navbar-primary bg-primary justify-content-between">
        <a class="navbar-brand text-light" href="#">
            <div class="row">
                <div class="col">
                    <img src="img/ArcadisLogoWhite.png" alt="Logo" height="30" class="mt-2"/>
                </div>
                <div class="col">
                   <h4 class="mb-0">Easing Sydney Congestion</h4>
                    <small>Climate Change Risk Assessment Tool</small>
                </div>
            </div>
        </a>
        <form class="form-inline">
            <b-button variant="outline-light" sm class="my-sm-0 ">
                Home
            </b-button>
        </form>
      
    </nav> -->
        <b-modal v-model="modalTermMatrixShow" size="lg">
            <!-- <img class="img-fluid" src="sampleImage.jpg" /> -->
            <iframe
                src="./TermMatrix.pdf"
                width="100%"
                height="700"
                style="border: none; overflow: hidden;"
            />
            <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="modalTermMatrixShow=false"
                >
                    Close
                </b-button>
            </div>
            </template>
        </b-modal>
    </header>
</template>

<script>
/*eslint-disable*/

export default {
  name: 'AppHeader',
  data() {
    return {
        activeTab: "home",
        modalTermMatrixShow: false,
        // stageTab: [
        //     {
        //         tab: "assetcontext",
        //         route: "/assetcontext",
        //         isActive: false,
        //     },
        //     {
        //         tab: "climatecontext",
        //         route: "/climatecontext",
        //         isActive: false,
        //     },
        //     {
        //         tab: "preliminaryassessment",
        //         route: "/preliminaryassessment",
        //         isActive: false,
        //     },
        //     {
        //         tab: "detailedassessment",
        //         route: "/detailedassessment",
        //         isActive: false,
        //     },
        //     {
        //         tab: "adaptationplanning",
        //         route: "/adaptationplanning",
        //         isActive: false,
        //     }
        // ],
        stageTab: {
            assetcontext: {
                tab: "assetcontext",
                route: "/assetcontext",
                isActive: false,
            },
            climatecontext: {
                tab: "climatecontext",
                route: "/climatecontext",
                isActive: false,
            },
            preliminaryassessment: {
                tab: "preliminaryassessment",
                route: "/preliminaryassessment",
                isActive: false,
            },
            detailedassessment: {
                tab: "detailedassessment",
                route: "/detailedassessment",
                isActive: false,
            },
            adaptationplanning: {
                tab: "adaptationplanning",
                route: "/adaptationplanning",
                isActive: false,
            }
        },
        isStageNavPath: sessionStorage.getItem("isStagePath")
    }
  },
  computed: {
    isStageNavActive: {
        get() {
            return this.$store.state.mainModule.isStageNavActive
        } 
    },
    projectId: {
        get(){
            if(this.$store.state.mainModule.currentProjectId != null){
                Object.keys(this.stageTab).forEach(item=>{
                    this.stageTab[item].isActive = false
                })
                this.stageTab['assetcontext'].isActive = true
            }
            return this.$store.state.mainModule.currentProjectId
        }
    },
  },
  methods: {
    setActiveTab: function(tabCode){
        const current = this
        current.activeTab = tabCode
        this.$store.commit('mainModule/clearCurrentProject')
        current.$store.commit("mapModule/clearCurrentProjectCRAEntries")
    },
    goToRoute: function(tab) {
        const current = this
        // var currentStageTabSelected = current.activeStageTab.filter(obj=>obj.route = routePath)[0]
        // currentStageTabSelected.isActive = true
        Object.keys(current.stageTab).forEach(item=>{
            current.stageTab[item].isActive = false
            if(item == tab){
                current.stageTab[item].isActive = true
            }
        })
        current.$router.push({
            path: current.stageTab[tab].route
        }).catch((e)=>{
            // error
        })
    }
  },
  mounted () {
    const current = this
    
    // console.log(sessionStorage.getItem("isStagePath"))
  }
}
</script>

<style scoped>
.buttonActiveCustom {
    color: #212529 !important;
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
}
.appHeader {
    overflow: hidden;
}
</style>